import React, {useState, useCallback, useEffect} from "react"
import Shell from '../components/global/shell'
import Helmet from "react-helmet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from 'styled-components'
import tw from "twin.macro"
import Container from '../components/global/container'
import { graphql } from 'gatsby' 
import SideNav from '../components/chapter/sidenav'
import { GatsbyImage } from "gatsby-plugin-image"

const Wrapper = styled.main`
  ${tw`md:pb-20`}

  h2 {
    ${tw`font-bold text-xl pt-3 md:pt-6 pb-1`}
  }

  h3 {
    ${tw`font-bold text-lg pt-4`}
  }

  h4 {
    ${tw`italic text-lg pt-2`}
  }

  p {
    ${tw`pb-3 md:pb-5 pt-2 text-sm md:text-xl md:leading-8`}
  }

  a {
    ${tw`text-blue-500 hover:underline`}
  }

  blockquote {
    ${tw`pl-5 text-xl md:text-base`}
  }

  table {
    ${tw`my-8`}
  }

  ul {
    ${tw`my-4`}
    li {
      list-style: disc;
      list-style-position: inside;
      ${tw`text-sm md:text-lg md:leading-8`}
    }
  }
`

const Chapter = (props) => {

  const data = props;

  // console.log(data.pageContext);

  const [ref, setRef] = useState(null);
  const [activeHeading, setActiveHeading] = useState(null);
  const [jumpedHeading, setJumpedHeading] = useState(null);

  // Intersection observer
  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setActiveHeading(entry.target.innerHTML);
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1
      });
      ref.querySelectorAll('h1, h2').forEach(section => {
        observer.observe(section);
      })
   
    }
  }, [ref]);

  // Trigger new render when maincontent changes
  const onRefChange = useCallback(node => {
    setRef(node);
  }, []);

  // Scroll to heading when user clicks nav
  useEffect(() => {
    if (ref) {
      ref.querySelectorAll('h1, h2').forEach(section => {
        if (section.innerHTML === jumpedHeading) {
          section.scrollIntoView();
        }
      })
    }
  }, [jumpedHeading])


  return (
    <Shell>
      <Helmet>
        <title>Grundlagen des E-Learning - {data.pageContext.title}</title>
      </Helmet>

      <Container>

      <div className="flex md:flex-row flex-wrap mt-12 md:mt-28">
          
          <div className="w-full md:w-1/4 pr-3">

            <div className="mb-8 border-t border-b pt-3 border-gray-300">
              <h1 className="text-3xl md:text-3xl font-bold pt-2 pb-2 text-gray-900 text-right">{data.pageContext.title}</h1>
              <h2 className="text-xl md:text-xl font-bold text-gray-600 text-right pb-2">Grundlagen des E-Learning</h2>
              <p className="text-gray-500 pb-3 text-right">Letztes Update: {data.pageContext.update}</p>
            </div>

            <div className="md:sticky top-0 hidden md:block mt-28">
              <nav className="">
                <h3 className="font-bold text-gray-900 pb-3 text-base">Inhaltsverzeichnis</h3>
                <SideNav content={ref} active={activeHeading} setJumpedHeading={setJumpedHeading} />
              </nav>
            </div>

          </div>

          <div className="w-full md:w-3/4 md:pl-8 md:mt-0" ref={onRefChange}>
            <Wrapper>
              <MDXRenderer>
                {data.pageContext.body}
              </MDXRenderer>
            </Wrapper>

          </div>
        </div>

      </Container>

    </Shell>
  )
}

export default Chapter;


export const query = graphql`
  query($slug: String!) {
    chapter: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`